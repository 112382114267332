

import React, { useState } from "react";
import Modal from "react-modal";
import CancelInspectionModal from "./CancelInspectionModal";
// Modal.setAppElement("#root");
import ResendReminderModal from "./ResendReminderModal"; // Import the new modal
import AssignInspectionModal from "./AssignInspectionModal.js";
import ReassignModel from "./ReassignModel.js";
import "bootstrap/dist/css/bootstrap.css";
import "./style.css";
import ViewformIcon from "./img/Frame 1000006023.png";
import cancelInspectionIcon from "./img/Cancel Inspection.png";
import resendReminderIcon from "./img/Resend Reminder.png";
import assignInspectionIcon from "./img/Assign Inspection.png";
import reassignIcon from "./img/Reassign.png";
import editIcon from "./img/Edit.png";
import archiveIcon from "./img/Archive.png";
import viewInspectionIcon from "./img/Group.png";
import filterIcon from "./img/filter.png";
import viewRequestIcon from "./img/Vector (1).png";

import { NavLink } from "react-router-dom";

const initialData = [
  {
    id: 1,
    araId: "WAYvmqNqk",
    company: "Ghrix",
    site: "688 SW Steedly Dr Lake City FL",
    approvalDate: "09/28/2023",
    nextInspectionDate: "09/28/2023",
    contactNumber: "9034433812",
    Status: "New Request",
  },
  {
    id: 2,
    araId: "WAYvmqNqk",
    company: "1st Choice Recovery LLC",
    site: "688 SW Steedly Dr Lake City FL",
    approvalDate: "09/28/2023",
    nextInspectionDate: "09/28/2023",
    contactNumber: "9034433812",
    Status: "In-Progress",
  },
  {
    id: 3,
    araId: "WAYvmqNqk",
    company: "59 Second Recovery LLC",
    site: "688 SW Steedly Dr Lake City FL",
    approvalDate: "09/28/2023",
    nextInspectionDate: "09/28/2023",
    contactNumber: "9034433812",
    Status: "To-Be-Approved",
  },
  {
    id: 4,
    araId: "WAYvmqNqk",
    company: "A & A Towing and Recovery MO",
    site: "688 SW Steedly Dr Lake City FL",
    approvalDate: "09/28/2023",
    nextInspectionDate: "09/28/2023",
    contactNumber: "9034433812",
    Status: "Approved",
  },
  {
    id: 5,
    araId: "WAYvmqNqk",
    company: "A Lenders Recovery Service",
    site: "688 SW Steedly Dr Lake City FL",
    approvalDate: "09/28/2023",
    nextInspectionDate: "09/28/2023",
    contactNumber: "9034433812",
    Status: "To-Be-Approved",
  },
  {
    id: 6,
    araId: "WAYvmqNqk",
    company: "A & A Towing and Recovery MO",
    site: "688 SW Steedly Dr Lake City FL",
    approvalDate: "09/28/2023",
    nextInspectionDate: "09/28/2023",
    contactNumber: "9034433812",
    Status: "Approved",
  },
];

const Inspections = () => {
  const [data, setData] = useState(initialData);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  // const [modalIsOpen, setModalIsOpen] = useState(false);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [resendModalIsOpen, setResendModalIsOpen] = useState(false);
  const [assignModalIsOpen, setAssignModalIsOpen] = useState(false);

  const [reassignModelIsOpen, setReassignModalIsOpen] = useState(false);


  const handleReassignClick = () => {
    setReassignModalIsOpen(true);

    };

    const handleAssigninspectionClick = () => {
      setAssignModalIsOpen(true);
  
    };

  const handleResendClick = () => {
    setResendModalIsOpen(true);

    console.log("Resend button clicked");
  };

  const handleCloseReassignModel = () => {
    setReassignModalIsOpen(false);
  };
  const handleCloseAssignModal = () => {
   setAssignModalIsOpen(false);
  };

  const handleCloseResendModal = () => {
    setResendModalIsOpen(false);
  };

  const handleCancelClick = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleDeleteRow = (id) => {
    const updatedData = data.filter((item) => item.id !== id);
    setData(updatedData);
  };

  const handleConfirmCancel = () => {
    // handle the cancellation logic here
    setModalIsOpen(false);
  };

  const filteredData = data.filter((item) =>
    item.company.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedData = filteredData.sort((a, b) => {
    if (sortColumn === null) return 0;
    if (a[sortColumn] < b[sortColumn]) return sortDirection === "asc" ? -1 : 1;
    if (a[sortColumn] > b[sortColumn]) return sortDirection === "asc" ? 1 : -1;
    return 0;
  });

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = sortedData.slice(indexOfFirstEntry, indexOfLastEntry);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSortColumn = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

 

  const handleViewMore = (id) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: true,
    }));
  };

  return (
    <div className="inspections-container  p-0">
      <div className="controls">
        <div className="show-entries col-lg-2">
          <label>Show </label>
          <select
            value={entriesPerPage}
            onChange={(e) => setEntriesPerPage(Number(e.target.value))}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </select>
          <label> entries</label>
        </div>

        <span className="searchbox">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </span>
        <img src={filterIcon} alt="" />
        <NavLink to="/add-request" style={{ textDecoration: "none" }}>
          <button
            className="add-inspection-button"
            // onClick={() => setModalIsOpen(true)}
          >
            + Add Request
          </button>
        </NavLink>
      </div>
      <div className="bodyCo ">
        <div class="table-responsive mt-5">
          <div className="table ">
            <table className="inspections-table">
              <thead className="thead">
                <tr>
                  <th
                    style={{ width: "5%" }}
                    onClick={() => handleSortColumn("id")}
                  >
                    ID{" "}
                    {sortColumn === "id" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th
                    style={{ width: "10%" }}
                    onClick={() => handleSortColumn("araId")}
                  >
                    ARA ID{" "}
                    {sortColumn === "araId" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th
                    style={{ width: "15%" }}
                    onClick={() => handleSortColumn("company")}
                  >
                    Company{" "}
                    {sortColumn === "company" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th
                    style={{ width: "17%" }}
                    onClick={() => handleSortColumn("site")}
                  >
                    Site{" "}
                    {sortColumn === "site" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th
                    style={{ width: "12%" }}
                    onClick={() => handleSortColumn("approvalDate")}
                  >
                    Approval Date{" "}
                    {sortColumn === "approvalDate" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th
                    style={{ width: "16%" }}
                    onClick={() => handleSortColumn("nextInspectionDate")}
                  >
                    Next Inspection Date{" "}
                    {sortColumn === "nextInspectionDate" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th
                    style={{ width: "14%" }}
                    onClick={() => handleSortColumn("Status")}
                  >
                    Status{" "}
                    {sortColumn === "Status" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th style={{ width: "10%" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentEntries.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.araId}</td>
                    <td>{item.company}</td>
                    <td>{item.site}</td>
                    <td>{item.approvalDate}</td>
                    <td>{item.nextInspectionDate}</td>
                    <td>
                      <div
                        className={`status-${item.Status.toLowerCase().replace(
                          /\s+/g,
                          "-"
                        )}`}
                      >
                        {item.Status}
                      </div>
                    </td>
                    <td>
                      {!expandedRows[item.id] ? (

                        <button
                          className="view-more-button"
                          onClick={() => handleViewMore(item.id)}
                        >
                           <NavLink to={`/request-form`} style={{ textDecoration: "none" }}>
                          <img
                            src={ViewformIcon}
                            alt="Frame"
                            title="View Form"
                            className=""
                          />
                          </NavLink>
                          <NavLink to={`/view-all-request`}>
                            <img
                              src={viewRequestIcon}
                              alt="Vector"
                              title="View Request"
                            />
                          </NavLink>
                          <NavLink
                            to="/view-inspection-request"
                            style={{ textDecoration: "none" }}
                          >
                            <img
                              src={viewInspectionIcon}
                              alt="Group"
                              title="View Inspection"
                            />
                          </NavLink>

                          <span className="view" style={{ textDecoration: 'none' }}>View More</span>
                        </button>
                      ) : (
                        <div className="additional-icons">
                          <img
                            src={cancelInspectionIcon}
                            alt="Cancel Inspection"
                            title="Cancel Inspection"
                            onClick={handleCancelClick}
                          />
                          <CancelInspectionModal
                            isOpen={modalIsOpen}
                            onRequestClose={handleCloseModal}
                            onConfirm={handleConfirmCancel}
                          />
                          <img
                            src={resendReminderIcon}
                            alt="Resend Reminder"
                            title="Resend Reminder"
                            onClick={handleResendClick}
                          />
                        
                          <ResendReminderModal
                            isOpen={resendModalIsOpen}
                            onRequestClose={handleCloseResendModal}
                          />
                          <img
                            src={assignInspectionIcon}
                            alt="Additional Icon 3"
                            title="assignInspection"
                            onClick={handleAssigninspectionClick}
                          />
                          <AssignInspectionModal
                            isOpen={assignModalIsOpen}
                            onRequestClose={handleCloseAssignModal}
                          />
                          <img
                            src={reassignIcon}
                            alt="Additional Icon 4"
                            title="reassign"
                            onClick={handleReassignClick}
                          />
                          <ReassignModel
                           isOpen={reassignModelIsOpen}
                           onRequestClose={handleCloseReassignModel}
                           />
                            <NavLink to={`/edit-request`} style={{ textDecoration: "none" }}>
                          <img
                            src={editIcon}
                            alt="Additional Icon 5"
                            title="edit"
                          />
                          </NavLink>
                          <img
                            src={archiveIcon}
                            alt="Additional Icon 6"
                            title="archive"
                            onClick={() => handleDeleteRow(item.id)}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="pagination">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          {[
            ...Array(Math.ceil(filteredData.length / entriesPerPage)).keys(),
          ].map((number) => (
            <button
              key={number + 1}
              onClick={() => handlePageChange(number + 1)}
              className={currentPage === number + 1 ? "active" : ""}
            >
              {number + 1}
            </button>
          ))}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={
              currentPage === Math.ceil(filteredData.length / entriesPerPage)
            }
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Inspections;
