import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import uploadIcon from "../IMg/uploadIcon.png";
import { useLocation, useNavigate } from "react-router-dom";
import { apiCall } from "../../../services/ApiCall";
import config from "../../../config/config.json";
import { IoIosCloseCircle } from "react-icons/io";
import Loader from "../../CompanyFolder/CompanyList/Loader";
import { Input } from "reactstrap";

const PhotoUploadSection = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [pictureData, setPictureData] = useState([]);
  const [uploadedImages, setUploadedImages] = useState({});
  const [loading, setLoading] = useState(false);
  const [explanations, setExplanations] = useState({});
  const fileInputRefs = useRef([]);

  const getPictureFormQuestionList = async () => {
    setLoading(true);

    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}getPictureFormQuestionList`,
        {},
        { type: "ALL" },
        "GET"
      );
      setLoading(false);

      if (isSuccess && Array.isArray(data.data)) {
        setPictureData(data.data);
      } else {
        console.error("API response is not an array:", data);
        setPictureData([]);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setPictureData([]);
    }
  };

  const getRequestPictures = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}getRequestPictures`,
        {},
        { requestId: location.state?.requestId },
        "GET"
      );
      if (isSuccess && data.data.length) {
        const tmpData = data.data[0]
        setUploadedImages(tmpData);
        for (let i = 1; i <= 19; i++) {
          const key = `p${i}a`;
          if (tmpData[key] !== null) {
            explanations[key] = true;
            explanations[`${key}value`] = tmpData[key];
          }
        }
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const uploadPicture = async (pictureUrl, pictureId) => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}uploadPicture`,
        {},
        { requestId: Number(location.state?.requestId), pictureId, pictureUrl },
        "POST"
      );
      if (isSuccess) {
        setUploadedImages(data.data[0]);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const deletedPicture = async (pictureUrl, pictureId) => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}deletedPicture`,
        {},
        { requestId: Number(location.state?.requestId), pictureId, pictureUrl },
        "POST"
      );
      if (isSuccess) {
        setUploadedImages(data.data[0]);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const handleFileChange = async (event, pictureId) => {
    try {
      const timestamp = new Date().getTime();
      const fileName = `${timestamp}_${event.target.files[0]?.name}`;
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      formData.append("fileName", fileName);
      formData.append("filePath", "msi/");

      const requestOptions = {
        method: "POST",
        body: formData,
      };

      const response = await fetch(
        `${config.AUTH_API_URL}uploadFile`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to upload file");
      }

      const result = await response.json();
      const url = result.url;
      uploadPicture(url, pictureId);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else {
      getPictureFormQuestionList();
    }
  }, [navigate]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else {
      getRequestPictures();
    }
  }, [location.state?.requestId]);

  const textStyle = {
    fontSize: "16px",
    color: "rgba(0, 80, 164, 1)",
  };

  const handleIconClick = (index) => {
    fileInputRefs.current[index].click();
  };

  const handleCheckboxChange = (index) => {
    if (explanations && explanations[index])
      deletedPicture(String(null), index + 'value');

    const parts = index.split('a');
    if (uploadedImages[parts[0]]) {
      deletedPicture(String(null), parts[0]);
    }
    setExplanations((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const uploadPictureData = async (pictureUrl, pictureId) => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}uploadPicture`,
        {},
        { requestId: Number(location.state?.requestId), pictureId, pictureUrl },
        "POST"
      );
      if (isSuccess) {
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const handleExplanationChange = (index, value) => {
    setExplanations((prev) => ({
      ...prev,
      [index]: value,
    }));
    uploadPictureData(value, index)
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="container mt-4" style={textStyle}>
      {pictureData.map((picture, index) => (
        <div className="mb-4" key={picture.id}>
          <h5 style={{ fontSize: "14px" }}>
            {picture.reportId}: {picture.title}
          </h5>
          <div className="p-2" style={{ border: "1px solid #0050A4" }}>
            <div
              style={{ borderBottom: "1px solid rgba(0, 80, 164, 1)" }}
              onClick={() => handleIconClick(index)}
            >
              <i
                style={{
                  backgroundColor: "#0050A4",
                  color: "white",
                  padding: "5px",
                  cursor: "pointer",
                }}
                className="bi bi-cloud-upload"
              ></i>
              <span style={{ marginLeft: "20em", cursor: "pointer" }}>
                Click here to upload the image
              </span>
            </div>
            <div className="d-flex">
              <div className="position-relative m-2 mt-4">
                <div style={{ position: "relative", display: "inline-block" }}>
                  {uploadedImages[picture.reportId.split(".")[0].toLowerCase()] ? (
                    <>
                      <img
                        src={uploadedImages[picture.reportId.split(".")[0].toLowerCase()]}
                        alt="Uploaded"
                        className="img-fluid"
                        style={{
                          background: "#EDF4FC",
                          padding: "20px",
                          height: "30vh",
                          width: "20vw",
                        }}
                      />
                      <IoIosCloseCircle
                        onClick={() =>
                          deletedPicture(
                            picture.reportId.split(".")[0].toLowerCase(),
                            picture.reportId.split(".")[0].toLowerCase()
                          )
                        }
                        style={{
                          position: "absolute",
                          top: "0px",
                          right: "0px",
                          fontSize: "24px",
                          color: "black",
                          cursor: "pointer",
                        }}
                      />
                    </>
                  ) : (
                    <img
                      src={uploadIcon}
                      alt="placeholder"
                      className="img-fluid"
                      style={{
                        background: "#EDF4FC",
                        padding: "20px",
                        height: "30vh",
                        width: "20vw",
                      }}
                    />
                  )}
                </div>
                <input
                  type="file"
                  accept="image/*"
                  ref={(ref) => (fileInputRefs.current[index] = ref)}
                  onChange={(e) =>
                    handleFileChange(e, picture.reportId.split(".")[0].toLowerCase())
                  }
                  style={{ display: "none" }}
                />


                {
                  picture.reportId == 'P19' ? <> 
                  <div className="mt-3">
                   <label
                    htmlFor={`unable-${index}`}
                    className="ms-2"
                    style={{ fontSize: "14px" }}
                  >
                    Remarks:
                  </label>
                    <Input
                      className="mt-2"
                      placeholder="Please explain location..."
                      value={explanations['p19avalue'] || ""}
                      onChange={(e) => {
                        uploadPictureData(e.target.value, 'p19avalue');
                        setExplanations((prev) => ({
                          ...prev,
                          p19avalue: e.target.value,
                        }));
                      }}
                      
                    />
                    </div>
                  </>
                    : <div className="mt-3">
                      <input
                        type="checkbox"
                        id={`unable-${index}`}
                        style={{ width: "20px" }}
                        checked={!!explanations[picture.reportId.split(".")[0].toLowerCase() + "a"]}
                        onChange={() => handleCheckboxChange(picture.reportId.split(".")[0].toLowerCase() + "a")}
                      />
                      <label
                        htmlFor={`unable-${index}`}
                        className="ms-2"
                        style={{ fontSize: "14px" }}
                      >
                        Unable to take photo, please explain
                      </label>
                      {explanations[picture.reportId.split(".")[0].toLowerCase() + "a"] && (
                        <Input
                          className="mt-2"
                          placeholder="Please explain why you were unable to take a photo..."
                          value={explanations[picture.reportId.split(".")[0].toLowerCase() + "a" + "value"] || ""}
                          onChange={(e) => handleExplanationChange(picture.reportId.split(".")[0].toLowerCase() + "a" + "value", e.target.value)}
                        />
                      )}
                    </div>
                }
              </div>
            </div>

          </div>
        </div>
      ))}
    </div>
  );
};

export default PhotoUploadSection;
