import Dashboard from "./components/DashboardFolder/Dashboard.js";
import { Navigate } from "react-router-dom";

/****Layouts*****/
import FullLayout from "./layouts/FullLayout.js";

/***** Pages ****/

import About from "./views/About.js";
import Alerts from "./views/ui/Alerts.js";
import Badges from "./views/ui/Badges.js";
import Buttons from "./views/ui/Buttons.js";
import Cards from "./views/ui/Cards.js";
import Grid from "./views/ui/Grid.js";
import Tables from "./views/ui/Tables.js";
import Forms from "./views/ui/Forms.js";
import Breadcrumbs from "./views/ui/Breadcrumbs.js";
import Requests from "./components/requests/Requests.js";
import Request from "./components/Request/Inspections.js";
import Invoice from "./components/invoice/InvoiceTable.js";
import CompanyList from "./components/ArchiveCompanylistFolder/Inspections.js";
import SiteListArchive from "./components/ArchiveSitesfolder/Inspections.js";
import InvoiceDetails from "./components/invoice/InvoiceDetails.js";
import Settings from "./components/Setting/App.js";
import SiteList from "./components/SiteFolder/sitelist/Inspections.js";
import AddSiteList from "./components/SiteFolder/sitelist/AddSite.js";
import SiteHistory from "./components/SiteFolder/sitelist/SiteHistory.js";
import CompanyHistory from "./components/CompanyFolder/CompanyList/CompanyHistory.js";
import CompanylistFolder from "./components/CompanyFolder/CompanyList/Inspections.js";
import CompanyView from "./components/CompanyFolder/CompanyList/ViewPage.js";
import CompanylistAddsite from "./components/CompanyFolder/CompanyList/AddCompany.js";
import RequestDetails from "./components/company/Company.js";
import AllRequest from "./components/archive/Inspections.js";
import ViewPage from "./components/CompanyFolder/CompanyList/ViewPage.js";
import EditCompany from "./components/CompanyFolder/CompanyList/EditCompany.js";
// import ViewRequest from "./components/CompanyFolder/CompanyList/viewRequestView.js";
import AddCompany from "./components/CompanyFolder/CompanyList/AddCompany.js";
import EditSite from "./components/SiteFolder/sitelist/EditSite.js";
import ViewSite from "./components/SiteFolder/sitelist/ViewSite.js";
import ViewRequestSiite from "./components/SiteFolder/sitelist/ViewRequestSite.js";
import AddSite from "./components/SiteFolder/sitelist/AddSite.js";
import ViewAllRequest from "./components/archive/viewRequest.js";
import AddRequestAllRequest from "./components/archive/AddRequest.js";
import ViewInspectionAllRequest from "./components/archive/ViewInspection.js";
import EditRequest from "./components/archive/EditRequest.js";
import CompanyArchive from "./components/ArchiveFolderForCompanyAndSites/ArchiveCompany/ArchiveCompanyFolder/CompanyList/Inspections.js";
import SiteArchive from "./components/ArchiveFolderForCompanyAndSites/ArchiveSites/SiteFolder/sitelist/Inspections.js"
import TabForm from "./components/TabForm/TabForm.js";
import Logout from "./components/login/out/login.js";
import PrivateRoute from "./components/PrivateRoute.js";
import ViewSiteDetailsPage from "./components/SiteFolder/sitelist/ViewPage.js";
import VieInspectionReport from "./components/Request/ViewInspection.js";
import AddRequest from "./components/Request/AddRequest.js";




const ThemeRoutes = [
  {
    path: "/",
    element: <Logout />,
    exact: true
  },
  {
    path: "/",
    element: (
      <PrivateRoute>
        <FullLayout />
      </PrivateRoute>
    ),
    children: [
      { path: "/", element: <Navigate to="/dashboard" /> },
      { path: "/view", exact: true, element: <ViewPage /> },
      { path: "/view-request-site", exact: true, element: <ViewRequestSiite /> },

      { path: "/log-out", exact: true, element: <Logout /> },


      { path: "/to-be-approved", exact: true, element: <Request type='CUSTOM' status='2' /> },

      { path: "/approved-Request", exact: true, element: <Request type='CUSTOM' status='4' /> },


      { path: "/edit-request", exact: true, element: <EditRequest /> },


      { path: "/view-inspection-request", exact: true, element: <VieInspectionReport /> },


      { path: "/add-request", exact: true, element: <AddRequest /> },

      { path: "/view-all-request", exact: true, element: < ViewAllRequest /> },


      { path: "/add-site", exact: true, element: <AddSite /> },

      { path: "/view-site", exact: true, element: <ViewSite /> },

      { path: "/edit-site", exact: true, element: <EditSite /> },


      { path: "/add-company", exact: true, element: <AddCompany /> },

      // { path: "/view-request", exact: true, element: <ViewRequest /> },
      { path: "/edit", exact: true, element: <EditCompany /> },
      { path: "/companiesarchive", exact: true, element: <AllRequest /> },
      { path: "/dashboard", exact: true, element: <Dashboard /> },
      { path: "/sitehistory", exact: true, element: <SiteHistory /> },
      { path: "/company-history", exact: true, element: <CompanyHistory /> },
      { path: "/company-list", exact: true, element: <CompanylistFolder /> },
      { path: "/company-list/view/:id", exact: true, element: <CompanyView /> },
      { path: "/company-list/edit/:id", exact: true, element: <EditCompany /> },

      { path: "/add-company", exact: true, element: <CompanylistAddsite /> },
      { path: "/request", exact: true, element: <Requests /> },
      { path: "/settings", exact: true, element: <Settings /> },
      { path: "/addSitelist", exact: true, element: <AddSiteList /> },
      { path: "/site-list", exact: true, element: <SiteList /> },
      { path: "/site-list/view/:id", exact: true, element: <SiteList /> },
      { path: "/archive-request", exact: true, element: <Request type='CUSTOM' status='9' /> },
      { path: "/archive-Company", exact: true, element: <CompanyArchive /> },
      { path: "/archive-Sites", exact: true, element: <SiteArchive /> },

      // { path: "/site-list/view/:id", exact: true, element: <ViewSiteDetailsPage /> },
      { path: "/site-list/edit/:id", exact: true, element: <EditSite /> },



      { path: "/siteList", exact: true, element: <SiteListArchive /> },
      { path: "/all-request", exact: true, element: <Request type='ALL' /> },
      { path: "/request-form", exact: true, element: <TabForm /> },
      { path: "/new-request", exact: true, element: <Request type='CUSTOM' status='0' /> },
      { path: "/CompanyList", exact: true, element: <CompanyList /> },
      { path: "/in-progress", exact: true, element: <Request type='CUSTOM' status='1' /> },
      { path: "/invoice", exact: true, element: <Invoice /> },
      { path: "/invoice/:id", exact: true, element: <InvoiceDetails /> },
      { path: "/requestDetail", exact: true, element: <RequestDetails /> },
      { path: "/about", exact: true, element: <About /> },
      { path: "/alerts", exact: true, element: <Alerts /> },
      { path: "/badges", exact: true, element: <Badges /> },
      { path: "/buttons", exact: true, element: <Buttons /> },
      { path: "/cards", exact: true, element: <Cards /> },
      { path: "/grid", exact: true, element: <Grid /> },
      { path: "/table", exact: true, element: <Tables /> },
      { path: "/forms", exact: true, element: <Forms /> },
      { path: "/breadcrumbs", exact: true, element: <Breadcrumbs /> },


    ],
  },
];

const routes = {
  ThemeRoutes,

};

export default routes;

