import React, { useEffect, useState } from 'react';
import './SiteDetail.css';
import deleteIcon from '../img/deleteIcon.png';
import exportIcon from '../img/exportIcon.png';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { apiCall } from '../../../services/ApiCall';
import { useNavigate } from 'react-router-dom';
import config from "../../../config/config.json";

function SiteDetail({ onSwitchToContact, answer, setAnswer }) {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({
    araId: '', companyId: '', state: '', street: ''
  });

  const [companyData, SetCompanyData] = useState([]);
  const validateAraId = (value) => (!value || !value.trim() ? 'ARA ID is required' : '');
  const validateCompany = (value) => (!value ? 'Company is required' : '');
  const validateState = (value) => (!value || !value.trim() ? 'State is required' : '');
  const validateStreet = (value) => (!value || !value.trim() ? 'Street is required' : '');

  const changeAraId = (e) => {
    const value = e.target.value;
    const error = validateAraId(value);
    setAnswer(prevAnswer => ({ ...prevAnswer, araId: value }));
    setErrors(prevErrors => ({ ...prevErrors, araId: error }));
  };

  const changeCompany = (e) => {
    const value = e.target.value;
    // console.log({value});s
    const error = validateCompany(value);
    setAnswer(prevAnswer => ({ ...prevAnswer, companyId: Number(value) }));
    setErrors(prevErrors => ({ ...prevErrors, companyId: error }));
  };

  const changeAddress2 = (e) => {
    setAnswer(prevAnswer => ({ ...prevAnswer, address2: e.target.value }));
  };

  const changeState = (e) => {
    const value = e.target.value;
    const error = validateState(value);
    setAnswer(prevAnswer => ({ ...prevAnswer, state: value }));
    setErrors(prevErrors => ({ ...prevErrors, state: error }));
  };

  const changeCity = (e) => {
    setAnswer(prevAnswer => ({ ...prevAnswer, city: e.target.value }));
  };

  const changeZipcode = (e) => {
    setAnswer(prevAnswer => ({ ...prevAnswer, zipcode: e.target.value }));
  };

  const changeRecheckcycle = (e) => {
    setAnswer(prevAnswer => ({ ...prevAnswer, recheckcycle: e.target.value }));
  };

  const handleSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const addressComponents = results[0].address_components;
      const getAddressComponent = (types) => {
        const component = addressComponents.find(comp => types.some(type => comp.types.includes(type)));
        return component ? component.long_name : '';
      };

      const street = getAddressComponent(['street_number']) + ' ' + getAddressComponent(['route']);
      const city = getAddressComponent(['locality']);
      const state = getAddressComponent(['administrative_area_level_1']);
      const zipcode = getAddressComponent(['postal_code']);

      setAnswer(prevAnswer => ({
        ...prevAnswer,
        address: street || address,
        city,
        state,
        zipcode
      }));
      setErrors(prevErrors => ({
        ...prevErrors,
        street: validateStreet(street || address),
        state: validateState(state)
      }));
    } catch (error) {
      console.error("Error fetching address details: ", error);
    }
  };




  const handleSubmit = (e) => {
    e.preventDefault();
    const araIdError = validateAraId(answer.araId);
    const companyError = validateCompany(answer?.companyId);
    const stateError = validateState(answer.state);
    const streetError = validateStreet(answer.street);

    if (araIdError || companyError || stateError || streetError) {
      setErrors({
        araId: araIdError,
        companyId: companyError,
        state: stateError,
        street: streetError
      });
      return;
    }

    onSwitchToContact();
  };


  const getCompanyList = async () => {
    const { isSuccess, data } = await apiCall(`${config.POST_LOGIN_API_URL}getCompanyList`, {}, {}, "GET");
    if (isSuccess) {
      SetCompanyData(data?.data?.reverse());
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else {
      getCompanyList();
    }
  }, []);


  return (
    <div className="add-site">
      <div className="header">
        <h2>Add Site</h2>
        <button className="export-btn m-1">
          <img className='me-1 ' src={exportIcon} alt=' ' /> Export
        </button>
      </div>
      <div className="tabs">
        <button className="tab active">Site Detail</button>
        <button className="tab" onClick={onSwitchToContact}>Site Contact</button>
      </div>
      <div className='site-detail-h'>
        <div className='site-detail-left'>Site Detail</div>
        <div className='site-detail-right'><img src={deleteIcon} alt='' /></div>
      </div>
      <div className="site-detail">
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              <span htmlFor="ara-id">ARA ID</span>
              <input
                value={answer.araId || ''}
                onChange={changeAraId}
                type="text"
                id="ara-id"
              />
              {errors.araId && <div className="error-message">{errors.araId}</div>}
            </div>
            {/* <div className="form-group">
              <span htmlFor="company">Company</span>
              <input
                value={answer.company || ''}
                onChange={changeCompany}
                type="text"
                id="company"
              />
              {errors.company && <div className="error-message">{errors.company}</div>}
            </div> */}
            <div className="form-group">
              <label style={{ fontSize: "13px", color: 'rgba(0, 80, 164, 1)', fontWeight: '400' }} htmlFor="company">Company</label>
              <select
                value={answer.companyId || ''}
                onChange={changeCompany}
                id="company"
              >
                <option value="" disabled>Select a company</option>
                {companyData && companyData.length && companyData.map((company) => (
                  <option key={company.id} value={company.id}>
                    {company.name}
                  </option>
                ))}
              </select>

              {errors.company && <div className="error-message">{errors.company}</div>}
            </div>

          </div>
          <div className="form-row">
            <div className="form-group">
              <span htmlFor="address">Address</span>
              <PlacesAutocomplete
                value={answer.street || ''}
                onChange={(value) => setAnswer({ ...answer, street: value })}
                onSelect={handleSelect}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div>
                    <input {...getInputProps({ placeholder: 'Search Street' })} />
                    <div>
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const style = {
                          backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                        };
                        return (
                          <div {...getSuggestionItemProps(suggestion, { style })}>
                            {suggestion.description}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              {errors.street && <div className="error-message">{errors.street}</div>}
            </div>
            <div className="form-group">
              <span htmlFor="address-2">Address 2</span>
              <input
                value={answer.address2 || ''}
                onChange={changeAddress2}
                type="text"
                id="address-2"
              />
            </div>
            <div className="form-group">
              <span htmlFor="state">State</span>
              <input
                value={answer.state || ''}
                onChange={changeState}
                type="text"
                id="state"
              />
              {errors.state && <div className="error-message">{errors.state}</div>}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <span htmlFor="city">City</span>
              <input
                value={answer.city || ''}
                onChange={changeCity}
                type="text"
                id="city"
              />
            </div>
            <div className="form-group">
              <span htmlFor="zipcode">Zipcode</span>
              <input
                value={answer.zipcode || ''}
                onChange={changeZipcode}
                type="text"
                id="zipcode"
              />
            </div>
            <div className="form-group">
              <span htmlFor="recheck-cycle">Recheck Cycle</span>
              <input
                value={answer.recheckcycle || ''}
                onChange={changeRecheckcycle}
                type="text"
                id="recheck-cycle"
              />
            </div>
          </div>
          <div className='text-end'>
            <button type="submit" className="next-btn ps-4 pe-4">Next</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SiteDetail;
