import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label, Input } from "reactstrap";
import { apiCall } from "../../../services/ApiCall";
import config from "../../../config/config.json";
import { useLocation, useNavigate } from "react-router-dom";

const DataFormSaveAnswer = (tabIndex) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [answers, setAnswers] = useState([]);
  const [module, setModule] = useState();
  const [changedAnswers, setChangedAnswers] = useState({});

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else {
      setModule(tabIndex.modules[Number(tabIndex.tabIndex)]);
      getRequestDataFormAnswer();
    }
  }, [tabIndex.tabIndex]);

  const getRequestDataFormAnswer = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}getRequestDataFormAnswer`,
        {},
        { requestId: location.state?.requestId },
        "GET"
      );
      if (isSuccess) {
        setAnswers(
          data.data[tabIndex.modules[Number(tabIndex.tabIndex)]?.moduleTitle]
        );
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const saveAnswerToBackend = async () => {
    try {
      const { isSuccess } = await apiCall(
        `${config.POST_LOGIN_API_URL}UpdateDateFormAnswer`,
        {},
        {
          requestId: Number(location.state?.requestId),
          answers: changedAnswers,
          module: tabIndex.modules[Number(tabIndex.tabIndex)]?.moduleTitle,
        },
        "POST"
      );
      if (isSuccess) {
        setChangedAnswers({})

      }
    } catch (error) {
      console.error("API error while saving answers:", error);
    }
  };

  useEffect(() => {
    if (changedAnswers && Object.keys(changedAnswers).length > 0) {
      saveAnswerToBackend();
    }
  }, [changedAnswers]);

  const getAnswerByKey = (answerKey) => {
    const result =
      answers && answers.find((item) => item.answerKey === answerKey);
    return result ? result.answer : null;
  };

  const handleChange = (e, questionId, type, option) => {
    const { value } = e.target;

    setAnswers((prevAnswers) => {
      if (prevAnswers.length === 0) {
        const newAnswer = {
          answerKey: questionId,
          answer: type === "checkbox" ? option : value,
        };
        setChangedAnswers((prev) => ({
          ...prev,
          [questionId]: newAnswer.answer,
        }));
        return [newAnswer];
      }

      let found = false;

      const updatedAnswers = prevAnswers.map((ans) => {
        if (ans.answerKey === questionId) {
          found = true;
          let updatedAnswer = ans.answer || '';
          if (type === "checkbox") {
            const currentAnswers = updatedAnswer
              ? updatedAnswer.split(",")
              : [];
            updatedAnswer = currentAnswers.includes(option)
              ? currentAnswers.filter((opt) => opt !== option).join(",")
              : [...currentAnswers, option].join(",");
            setChangedAnswers((prev) => ({
              ...prev,
              [questionId]: updatedAnswer,
            }));
            return { ...ans, answer: updatedAnswer };

          } else if (type === "radio" || type === "text") {
            updatedAnswer = value;
            setChangedAnswers((prev) => ({
              ...prev,
              [questionId]: updatedAnswer,
            }));
            return { ...ans, answer: updatedAnswer };
          }
        }
        return ans;
      });

      if (!found) {
        const newAnswer = {
          answerKey: questionId,
          answer: type === "checkbox" ? option : value,
        };
        setChangedAnswers((prev) => ({
          ...prev,
          [questionId]: newAnswer.answer,
        }));
        updatedAnswers.push(newAnswer);
      }

      return updatedAnswers;
    });
  };



  return (
    <Form>
      {module &&
        module.questions.map((question) => (
          <FormGroup key={question.id}>
            <Label style={{ color: "#0050A4", fontSize: "13px" }}>
              {question.id === 1
                ? question.questionTitle
                : `${question.reportId}: ${question.questionTitle}`}
            </Label>

            {question.subQuestions.length === 0 ? (
              <Input
                style={{
                  border: "1px solid #0050A4",
                  color: "#0050A4",
                }}
                className="me-4 "
                type="text"
                name={`question-${question.id}`}
                value={getAnswerByKey(question.reportId)}
                onChange={(e) => handleChange(e, question.reportId, "text")}
              />
            ) : (
              question.subQuestions.map((subQuestion) => (
                <div key={subQuestion.id}>
                  <FormGroup>
                    {subQuestion.optionType === "text" && (
                      <>
                        <Label>
                          <div
                            style={{ color: "#0050A4", fontSize: "20px" }}
                            className="mt-4"
                          >
                            {subQuestion.option}
                          </div>
                          <Input
                            style={{
                              border: "1px solid #0050A4",
                              color: "#0050A4",
                              width: "300px",
                            }}
                            className="mt-2"
                            type="text"
                            name={`subQuestion-${subQuestion.id}`}
                            value={getAnswerByKey(question?.reportId)}
                            onChange={(e) =>
                              handleChange(e, question.reportId, "text")
                            }
                          />
                        </Label>
                      </>
                    )}

                    {subQuestion.optionType === "checkbox" && (
                      <>
                        <Label style={{ display: "flex", marginTop: "10px" }}>
                          <Input
                            type="checkbox"
                            name={`subQuestion-${subQuestion.id}`}
                            checked={typeof getAnswerByKey(question?.reportId) === 'string'
                              ? getAnswerByKey(question?.reportId)?.split(",").includes(subQuestion.option.trim())
                              : false
                            }
                            onChange={(e) =>
                              handleChange(
                                e,
                                question.reportId,
                                "checkbox",
                                subQuestion.option
                              )
                            }
                          />
                          <span style={{ fontSize: "13px", color: "#0050A4", marginLeft: '10px' }}>
                            {subQuestion.option}
                          </span>
                        </Label>
                        {subQuestion.details && subQuestion.details.map((detail) => (
                          <FormGroup key={detail.id}>
                            {getAnswerByKey(question?.reportId)?.split(",").includes(subQuestion.option.trim()) && (
                              <>
                                <Label>
                                  <div
                                    style={{ color: "#0050A4", fontSize: "20px", fontSize: '14px' }}
                                    className=" ms-4"
                                  >
                                    {detail.option}
                                  </div>
                                  <Input
                                    style={{
                                      border: "1px solid #0050A4",
                                      color: "#0050A4",
                                      width: "300px",
                                    }}
                                    className="mt-2 ms-4"
                                    type="text"
                                    name={`subQuestion-${detail.id}`}
                                    value={getAnswerByKey(detail?.answerKey)}
                                    onChange={(e) =>
                                      handleChange(e, detail?.answerKey, "text")
                                    }
                                  />
                                </Label>
                              </>
                            )}
                          </FormGroup>
                        ))}
                      </>
                    )}

                    {subQuestion.optionType === "radio" && (
                      <>
                        <Label style={{ display: "flex", marginTop: "10px" }}>
                          <Input
                            type="radio"
                            name={`subQuestion-${subQuestion.id}`}
                            value={subQuestion.option}
                            checked={
                              getAnswerByKey(question?.reportId) === subQuestion.option
                            }
                            onChange={(e) =>
                              handleChange(
                                e,
                                question.reportId,
                                "radio"
                              )
                            }
                          />
                          <span style={{ fontSize: "13px", color: "#0050A4", marginLeft: '10px' }}>
                            {subQuestion.option}
                          </span>
                        </Label>
                        {subQuestion.details && subQuestion.details.map((detail) => (
                          <FormGroup key={detail.id}>
                            {getAnswerByKey(question?.reportId) === subQuestion.option && (
                              <>
                                <Label>
                                  <div
                                    style={{ color: "#0050A4", fontSize: "20px", fontSize: '14px' }}
                                    className=" ms-4"
                                  >
                                    {detail.option}
                                  </div>
                                  <Input
                                    style={{
                                      border: "1px solid #0050A4",
                                      color: "#0050A4",
                                      width: "300px",
                                    }}
                                    className="mt-2 ms-4"
                                    type="text"
                                    name={`subQuestion-${detail.id}`}
                                    value={getAnswerByKey(detail?.answerKey)}
                                    onChange={(e) =>
                                      handleChange(e, detail?.answerKey, "text")
                                    }
                                  />
                                </Label>
                              </>
                            )}
                          </FormGroup>
                        ))}
                      </>
                    )}
                  </FormGroup>

                </div>
              ))

            )}
          </FormGroup>
        ))}
    </Form>
  );
};

export default DataFormSaveAnswer;
